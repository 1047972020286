import React from 'react';
import { Layout, Avatar, Typography } from 'antd';

const { Header: AntHeader } = Layout;
const { Text } = Typography;

const Header: React.FC = () => {
  return (
    <AntHeader className="bg-red-600 flex justify-between items-center p-4">
      <div className="flex items-center">
        <Avatar src={`${process.env.PUBLIC_URL}/img/logo.png`} size={56} />
      </div>
      <div className="text-white flex items-center">
        <Text className="text-white">Erisson Almeida | Mesa #4</Text>
      </div>
    </AntHeader>
  );
};

export default Header;