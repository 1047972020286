import React, { useState } from 'react';
import { Button, notification } from 'antd';
import { PlusCircleOutlined, DollarCircleOutlined } from '@ant-design/icons';
import OrderConfirmationModal from './OrderConfirmationModal';

type Product = {
  id: number;
  name: string;
  quantity?: number;
  price: number;
};

type FooterProps = {
  total: number;
  selectedItems: Product[];
  previousOrders: Product[];
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  finalizeOrder: () => void;
};

const Footer: React.FC<FooterProps> = ({ total, selectedItems, previousOrders, isDrawerOpen, toggleDrawer, finalizeOrder }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [touchStartY, setTouchStartY] = useState<number | null>(null);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (touchStartY !== null) {
      const touchEndY = e.touches[0].clientY;
      if (touchStartY - touchEndY > 50) {
        if (!isDrawerOpen) {
          toggleDrawer();
        }
        setTouchStartY(null);
      } else if (touchEndY - touchStartY > 50) {
        if (isDrawerOpen) {
          toggleDrawer();
        }
        setTouchStartY(null);
      }
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    finalizeOrder();
    notification.success({
      message: 'Pedido Finalizado',
      description: 'Seu pedido foi finalizado com sucesso!',
      placement: 'topRight',
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOrderMore = () => {
    toggleDrawer();
  };

  return (
    <div className="relative">
      {isDrawerOpen && (
        <div className="fixed inset-0 bg-black opacity-30 z-10" onClick={toggleDrawer}></div>
      )}
      <footer
        className="bg-red-600 py-4 px-6 text-center fixed bottom-0 w-full z-20 shadow-lg rounded-t-2xl"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <div className="flex justify-center mb-3">
          <div className="w-10 h-1 bg-gray-200 rounded-full"></div>
        </div>
        <div className="flex justify-center text-gray-300 text-sm mb-2">Deslize para cima para ver mais</div>
        <div className="flex flex-col items-center">
          <div className="flex items-center space-x-2 mb-2">
            <span className="text-white font-semibold text-lg">Total:</span>
            <span className="text-white font-bold text-xl">R$ {total.toFixed(2)}</span>
          </div>
          <div className="flex space-x-4">
            <Button
              type="primary"
              icon={<DollarCircleOutlined />}
              className="bg-white text-red-600 font-bold"
              size="large"
              onClick={handleOrderMore}
              >
              Fechar Conta
            </Button>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              className={`font-bold ${selectedItems.length === 0 ? 'bg-gray-400 text-white' : 'bg-white text-red-600'}`}
              size="large"
              onClick={showModal}
              disabled={selectedItems.length === 0}
            >
              Pedir Items
            </Button>
          </div>
        </div>
      </footer>
      <OrderConfirmationModal
        isVisible={isModalVisible}
        selectedItems={selectedItems}
        total={total}
        onOk={handleOk}
        onCancel={handleCancel}
      />
      <div
        className={`fixed bottom-0 left-0 right-0 bg-gray-50 shadow-lg p-4 overflow-y-auto transform transition-transform duration-300 ease-in-out ${isDrawerOpen ? 'translate-y-0' : 'translate-y-full'
          } rounded-t-2xl`}
        style={{ maxHeight: '65vh', height: '65vh', zIndex: 30 }}
      >
        <h3 className="font-bold text-lg mb-2 text-gray-800">Itens Selecionados</h3>
        {selectedItems.length > 0 ? (
          selectedItems.map(item => (
            <div key={item.id} className="flex justify-between items-center mb-2 text-gray-700">
              <span>{item.name} x {item.quantity ?? 0}</span>
              <span>R$ {(item.price * (item.quantity ?? 1)).toFixed(2)}</span>
            </div>
          ))
        ) : (
          <p className="text-gray-500">Nenhum item selecionado.</p>
        )}

        <hr className="my-4" />

        <h3 className="font-bold text-lg mb-2 text-gray-800">Pedidos Anteriores</h3>
        {previousOrders.length > 0 ? (
          previousOrders.map(item => (
            <div key={item.id} className="flex justify-between items-center mb-2 text-gray-700">
              <span>{item.name} x {item.quantity ?? 0}</span>
              <span>R$ {item.price.toFixed(2)}</span>
            </div>
          ))
        ) : (
          <p className="text-gray-500">Nenhum pedido anterior.</p>
        )}
      </div>
    </div>
  );
};

export default Footer;