import React from 'react';

type Product = {
  id: number;
  name: string;
  quantity?: number;
  price: number;
  specifications?: string;
};

const OrderItem: React.FC<Product> = ({ name, quantity, specifications }) => {
  return (
    <div className="flex flex-col mb-2">
      <div className="flex justify-between items-center">
        <span>{name} x {quantity ?? 0}</span>
      </div>
      {specifications && (
        <div className="text-sm text-gray-500">
          <span>Especificações: {specifications}</span>
        </div>
      )}
    </div>
  );
};

export default OrderItem;