import React from 'react';
import { Tabs, ConfigProvider } from 'antd';
import OrderCard from './OrderCard';
import { Order } from '../types';

type KitchenTabsProps = {
  pendingOrders: Order[];
  readyOrders: Order[];
  activeTab: number;
  setActiveTab: (tab: number) => void;
  handleOrderReady: (id: number) => void;
};

const KitchenTabs: React.FC<KitchenTabsProps> = ({
  pendingOrders,
  readyOrders,
  activeTab,
  setActiveTab,
  handleOrderReady
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            colorPrimary: '#d32f2f',
          },
        },
      }}
    >
      <Tabs
        activeKey={activeTab.toString()}
        onChange={(key: string) => setActiveTab(parseInt(key, 10))}
        centered
        type="line"
        tabBarStyle={{
          backgroundColor: '#fff',
          borderBottom: '2px solid #e0e0e0',
        }}
      >
        <Tabs.TabPane
          tab={
            <span style={{
              color: activeTab === 1 ? '#d32f2f' : '#555',
            }}>
              Pedidos Pendentes
            </span>
          }
          key="1"
        >
          {pendingOrders.length > 0 ? (
            pendingOrders.map(order => (
              <OrderCard key={order.id} {...order} onReady={handleOrderReady} />
            ))
          ) : (
            <p className="text-gray-500 text-center text-lg">Nenhum pedido pendente.</p>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span style={{
              color: activeTab === 2 ? '#d32f2f' : '#555',
            }}>
              Pedidos Prontos
            </span>
          }
          key="2"
        >
          {readyOrders.length > 0 ? (
            readyOrders.map(order => (
              <OrderCard key={order.id} {...order} onReady={() => {}} />
            ))
          ) : (
            <p className="text-gray-500 text-center text-lg">Nenhum pedido pronto.</p>
          )}
        </Tabs.TabPane>
      </Tabs>
    </ConfigProvider>
  );
};

export default KitchenTabs;