import React from 'react';
import { Tabs, ConfigProvider } from 'antd';

type ProductCategory = {
  id: number;
  label: string;
};

type MenuTabsProps = {
  categories: ProductCategory[];
  activeTab: number;
  setActiveTab: (tab: number) => void;
};

const MenuTabs: React.FC<MenuTabsProps> = ({ categories, activeTab, setActiveTab }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            colorPrimary: '#d32f2f',
          },
        },
      }}
    >
      <Tabs
        activeKey={activeTab.toString()}
        onChange={(key: string) => setActiveTab(parseInt(key, 10))}
        centered
        type="line"
        tabBarStyle={{
          backgroundColor: '#fff',
          borderBottom: '2px solid #e0e0e0',
        }}
      >
        {categories.map(category => (
          <Tabs.TabPane
            tab={
              <span style={{
                color: activeTab === category.id ? '#d32f2f' : '#555',
              }}>
                {category.label}
              </span>
            }
            key={category.id.toString()}
          />
        ))}
      </Tabs>
    </ConfigProvider>
  );
};

export default MenuTabs;