import React, { useState } from 'react';
import { Layout } from 'antd';
import KitchenTabs from './../components/KitchenTabs';
import Clock from './../components/Clock';
import { Order } from '../types';

const initialOrders: Order[] = [
  {
    id: 1,
    items: [
      { id: 1, name: 'Pizza', quantity: 2, price: 20, specifications: 'Sem cebola' },
      { id: 2, name: 'Refrigerante', quantity: 2, price: 5 }
    ],
    time: '12:30',
    table: 5
  },
  {
    id: 2,
    items: [
      { id: 3, name: 'Hambúrguer', quantity: 1, price: 15, specifications: 'Sem picles, sem cebola' }
    ],
    time: '12:45',
    table: 3
  }
];

const { Header, Content } = Layout;

const Kitchen: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>(initialOrders);
  const [activeTab, setActiveTab] = useState<number>(1);

  const handleOrderReady = (orderId: number) => {
    const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    setOrders(orders.map(order =>
      order.id === orderId ? { ...order, isReady: true, finishedTime: currentTime } : order
    ));
  };

  const pendingOrders = orders.filter(order => !order.isReady);
  const readyOrders = orders.filter(order => order.isReady);

  return (
    <Layout className="min-h-screen bg-gray-100">
      <Header className="bg-red-600 shadow-md flex justify-between items-center p-4">
        <div className="flex-1 flex">
          <h1 className="text-white text-3xl font-bold">Cozinha - Pedidos</h1>
        </div>
        <Clock />
      </Header>
      <Content className="p-6">
        <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
          <KitchenTabs
            pendingOrders={pendingOrders}
            readyOrders={readyOrders}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            handleOrderReady={handleOrderReady}
          />
        </div>
      </Content>
    </Layout>
  );
};

export default Kitchen;