import React, { useState } from 'react';
import { Layout } from 'antd';
import WaiterTabs from '../components/WaiterTabs';
import Clock from '../components/Clock';
import { Order, DirectOrder, BillRequest } from '../types';

const { Header, Content } = Layout;

const initialDirectOrders: DirectOrder[] = [
  { id: 1, item: 'Água', table: 2, time: '13:00' },
  { id: 2, item: 'Refrigerante', table: 4, time: '13:15' }
];

const initialReadyOrders: Order[] = [
  { id: 1, items: [{ id: 1, name: 'Pizza', price: 20 }], time: '12:30', table: 5, isReady: true, finishedTime: '12:45' }
];

const initialBillRequests: BillRequest[] = [
  { id: 1, table: 3, time: '14:00' },
  { id: 2, table: 6, time: '14:30' }
];

const WaiterPage: React.FC = () => {
  const [directOrders, setDirectOrders] = useState<DirectOrder[]>(initialDirectOrders);
  const [readyOrders, setReadyOrders] = useState<Order[]>(initialReadyOrders);
  const [billRequests, setBillRequests] = useState<BillRequest[]>(initialBillRequests);
  const [activeTab, setActiveTab] = useState<number>(1);

  const markAsServed = (id: number, isDirectOrder: boolean) => {
    if (isDirectOrder) {
      setDirectOrders(directOrders.map(order => (order.id === id ? { ...order, served: true } : order)));
    } else {
      setReadyOrders(readyOrders.map(order => (order.id === id ? { ...order, isReady: false } : order)));
    }
  };

  const handleBillRequest = (id: number) => {
    setBillRequests(billRequests.map(req => (req.id === id ? { ...req, handled: true } : req)));
  };

  return (
    <Layout className="min-h-screen bg-gray-100">
      <Header className="bg-red-600 shadow-md flex justify-between items-center p-4">
        <div className="flex-1 flex">
          <h1 className="text-white text-3xl font-bold">Garçom - Pedidos</h1>
        </div>
        <Clock />
      </Header>
      <Content className="p-6">
        <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
          <WaiterTabs
            directOrders={directOrders.filter(order => !order.served)}
            readyOrders={readyOrders.filter(order => order.isReady)}
            billRequests={billRequests.filter(req => !req.handled)}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            handleServeOrder={markAsServed}
            handleBillRequest={handleBillRequest}
          />
        </div>
      </Content>
    </Layout>
  );
};

export default WaiterPage;