import React, { useState } from 'react';
import Header from '../components/Header';
import MenuTabs from '../components/MenuTabs';
import ProductList from '../components/ProductList';
import Footer from '../components/Footer';
import FloatingButton from '../components/FloatingButton';

type ProductCategory = {
  id: number;
  label: string;
};

type Product = {
  id: number;
  name: string;
  price: number;
  imgSrc: string;
  description?: string;
};

const categories: ProductCategory[] = [
  { id: 1, label: 'Hambúrgueres' },
  { id: 2, label: 'Bebidas' },
  { id: 3, label: 'Porções' },
];

const productsData: Record<number, Product[]> = {
  1: [
    {
      id: 1,
      name: 'Classic Burger',
      price: 38.99,
      imgSrc: 'https://media-public.canva.com/1hil4/MAEyJl1hil4/1/s2.jpg',
      description: 'Pão brioche, carne 174g, molho especial, queijo cheddar, picles e alface.',
    },
    {
      id: 2,
      name: 'Cheddar Burger',
      price: 35.99,
      imgSrc: 'https://media-public.canva.com/1hil4/MAEyJl1hil4/1/s2.jpg',
      description: 'Pão australiano, carne 174g, molho barbecue, queijo cheddar derretido.',
    },
    {
      id: 3,
      name: 'Bacon Burger',
      price: 42.99,
      imgSrc: 'https://media-public.canva.com/1hil4/MAEyJl1hil4/1/s2.jpg',
      description: 'Pão brioche, carne 174g, bacon crocante, queijo cheddar, cebola crispy.',
    },
  ],
  2: [
    {
      id: 4,
      name: 'Coca-Cola',
      price: 5.99,
      imgSrc: 'https://tezegw.vteximg.com.br/arquivos/ids/262561-1000-1000/72418_15.png?v=638361957148500000',
      description: 'Lata 350ml, refrigerante de cola.',
    },
    {
      id: 5,
      name: 'Suco de Laranja',
      price: 6.99,
      imgSrc: 'https://www.citrosuco.com.br/wp-content/uploads/2022/02/THUMB-05.png',
      description: 'Copo 300ml, suco natural de laranja.',
    },
  ],
  3: [
    {
      id: 6,
      name: 'Batata Frita',
      price: 12.99,
      imgSrc: 'https://www.tendaatacado.com.br/dicas/wp-content/uploads/2022/06/como-fazer-batata-frita-topo.jpg',
      description: 'Porção média de batatas fritas crocantes.',
    },
    {
      id: 7,
      name: 'Onion Rings',
      price: 14.99,
      imgSrc: 'https://static01.nyt.com/images/2020/04/22/dining/ejm-sourdough-03/ejm-sourdough-03-master768.jpg?width=320&quality=75&auto=webp',
      description: 'Porção de anéis de cebola empanados e fritos.',
    },
  ],
};

const Home: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [quantities, setQuantities] = useState<Record<number, number>>({});
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [previousOrders, setPreviousOrders] = useState<Product[]>([]);
  const [touchStartX, setTouchStartX] = useState<number | null>(null);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (touchStartX !== null) {
      const touchEndX = e.touches[0].clientX;
      if (touchStartX - touchEndX > 50) {
        navigateToNextTab();
        setTouchStartX(null);
      } else if (touchEndX - touchStartX > 50) {
        navigateToPreviousTab();
        setTouchStartX(null);
      }
    }
  };

  const navigateToNextTab = () => {
    const currentIndex = categories.findIndex(cat => cat.id === activeTab);
    const nextTab = categories[(currentIndex + 1) % categories.length].id;
    setActiveTab(nextTab);
  };

  const navigateToPreviousTab = () => {
    const currentIndex = categories.findIndex(cat => cat.id === activeTab);
    const previousTab = categories[(currentIndex - 1 + categories.length) % categories.length].id;
    setActiveTab(previousTab);
  };

  const handleQuantityChange = (id: number, change: number) => {
    setQuantities((prev) => ({
      ...prev,
      [id]: Math.max((prev[id] || 0) + change, 0),
    }));
  };

  const finalizeOrder = () => {
    const newOrders = selectedItems.map(item => ({ ...item }));
    setPreviousOrders([...previousOrders, ...newOrders]);
    setQuantities({});
  };

  const total = Object.entries(quantities).reduce((acc, [id, quantity]) => {
    const product = Object.values(productsData).flat().find(p => p.id === parseInt(id));
    return acc + (product ? product.price * quantity : 0);
  }, 0);

  const selectedItems = Object.entries(quantities)
    .filter(([_, quantity]) => quantity > 0)
    .map(([id, quantity]) => {
      const product = Object.values(productsData).flat().find(p => p.id === parseInt(id));
      if (product) {
        return { ...product, quantity };
      }
      return null;
    })
    .filter((item): item is Product & { quantity: number } => item !== null);

  return (
    <div
      className="bg-gray-100 min-h-screen flex flex-col"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <Header />
      <MenuTabs categories={categories} activeTab={activeTab} setActiveTab={setActiveTab} />
      <main className="flex-1">
        <ProductList
          products={productsData[activeTab]}
          onAdd={(id) => handleQuantityChange(id, 1)}
          onRemove={(id) => handleQuantityChange(id, -1)}
          quantities={quantities}
        />
      </main>
      <FloatingButton />
      <Footer
        total={total}
        selectedItems={selectedItems}
        previousOrders={previousOrders}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
        finalizeOrder={finalizeOrder}
      />
    </div>
  );
};

export default Home;