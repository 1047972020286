import React, { useState, useEffect } from 'react';

const Clock: React.FC = () => {
  const [time, setTime] = useState<string>(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="text-2xl font-bold text-red-600 bg-white px-4 py-2 rounded-lg shadow-lg">
      {time}
    </div>
  );
};

export default Clock;