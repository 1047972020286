import React, { useState, useRef, useEffect } from 'react';
import { Card, Button } from 'antd';
import { useSwipeable } from 'react-swipeable';
import { ArrowRightOutlined } from '@ant-design/icons';
import { DirectOrder, Order } from '../types';

type WaiterOrderCardProps = {
  order: DirectOrder | Order;
  onServed: (id: number) => void;
  isDirectOrder?: boolean;
};

const WaiterOrderCard: React.FC<WaiterOrderCardProps> = ({ order, onServed, isDirectOrder = false }) => {
  const [translateX, setTranslateX] = useState(0);
  const cardRef = useRef<HTMLDivElement>(null);
  const [cardWidth, setCardWidth] = useState(0);

  useEffect(() => {
    if (cardRef.current) {
      setCardWidth(cardRef.current.offsetWidth);
    }
  }, []);

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      if (eventData.dir === 'Right') {
        setTranslateX(Math.min(eventData.deltaX, cardWidth));
      }
    },
    onSwipedRight: () => {
      if (translateX > cardWidth * 0.50) {
        setTranslateX(cardWidth);
        setTimeout(() => {
          onServed(order.id);
          setTranslateX(0);
        }, 300);
      } else {
        setTranslateX(0);
      }
    },
    onSwiped: () => {
      if (translateX <= cardWidth * 0.50) {
        setTranslateX(0);
      }
    },
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  return (
    <div ref={cardRef} style={{ overflow: 'hidden', position: 'relative', marginBottom: '16px' }}>
      <div {...handlers}>
        <Card
          title={<span className="text-white font-bold">{isDirectOrder ? 'Pedido Direto' : `Pedido #${order.id}`}</span>}
          bordered={false}
          className="shadow-md rounded-lg transform transition-transform duration-300 ease-out"
          headStyle={{ backgroundColor: '#DC2626' }}
          style={{
            transform: `translateX(${translateX}px)`,
          }}
        >
          <div className="text-gray-600 mb-4">
            <p><strong>Mesa:</strong> {order.table}</p>
            <p><strong>Hora do Pedido:</strong> {order.time}</p>
            {'finishedTime' in order && order.finishedTime && (
              <p><strong>Finalizado às:</strong> {order.finishedTime}</p>
            )}
          </div>
          <div className="border-t border-gray-200 mt-4 pt-4">
            {'items' in order
              ? order.items.map(item => (
                  <div key={item.id}>
                    <p>{item.name}</p>
                  </div>
                ))
              : <p>{order.item}</p>
            }
          </div>
          <Button type="primary" className="bg-red-600 border-none hover:bg-red-500 mt-4 w-full" onClick={() => onServed(order.id)}>
            Servir Pedido
          </Button>
          <div className="flex items-center justify-end mt-2 animate-pulse">
            <span className="text-sm text-gray-500 mr-2">Deslize para servir</span>
            <ArrowRightOutlined style={{ fontSize: '24px', color: '#DC2626' }} />
          </div>
        </Card>
      </div>
      <hr />
    </div>
  );
};

export default WaiterOrderCard;