import React from 'react';
import { Button, notification } from 'antd';
import { BellOutlined } from '@ant-design/icons';

const FloatingButton: React.FC = () => {
  const handleClick = () => {
    notification.success({
        message: 'Garçom Sinalizado',
        description: 'Um garçom esta indo ate voce!',
        placement: 'topRight',
      });  };

  return (
    <div className="fixed bottom-40 right-5 flex items-center space-x-3">
      <span className="text-gray-500 px-0 rounded-full font-bold opacity-80 animate-pulse">
        Chamar Garçom
      </span>
      <Button
        type="primary"
        shape="circle"
        icon={<BellOutlined />}
        size="large"
        className="bg-red-600 border-red-600"
        onClick={handleClick}
      />
    </div>
  );
};

export default FloatingButton;