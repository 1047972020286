import React from 'react';
import { Tabs, ConfigProvider, Button } from 'antd';
import WaiterOrderCard from './WaiterOrderCard';
import { DirectOrder, Order, BillRequest } from '../types';

type WaiterTabsProps = {
  directOrders: DirectOrder[];
  readyOrders: Order[];
  billRequests: BillRequest[];
  activeTab: number;
  setActiveTab: (tab: number) => void;
  handleServeOrder: (id: number, isDirect: boolean) => void;
  handleBillRequest: (id: number) => void;
};

const WaiterTabs: React.FC<WaiterTabsProps> = ({
  directOrders,
  readyOrders,
  billRequests,
  activeTab,
  setActiveTab,
  handleServeOrder,
  handleBillRequest
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            colorPrimary: '#d32f2f',
          },
        },
      }}
    >
      <Tabs
        activeKey={activeTab.toString()}
        onChange={(key: string) => setActiveTab(parseInt(key, 10))}
        centered
        type="line"
        tabBarStyle={{
          backgroundColor: '#fff',
          borderBottom: '2px solid #e0e0e0',
        }}
      >
        <Tabs.TabPane
          tab={
            <span style={{
              color: activeTab === 1 ? '#d32f2f' : '#555',
            }}>
              Pedidos Diretos
            </span>
          }
          key="1"
        >
          {directOrders.length > 0 ? (
            directOrders.map(order => (
              <WaiterOrderCard key={order.id} order={order} onServed={(id) => handleServeOrder(id, true)} isDirectOrder />
            ))
          ) : (
            <p className="text-gray-500 text-center text-lg">Nenhum pedido direto.</p>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span style={{
              color: activeTab === 2 ? '#d32f2f' : '#555',
            }}>
              Pratos Prontos
            </span>
          }
          key="2"
        >
          {readyOrders.length > 0 ? (
            readyOrders.map(order => (
              <WaiterOrderCard key={order.id} order={order} onServed={(id) => handleServeOrder(id, false)} />
            ))
          ) : (
            <p className="text-gray-500 text-center text-lg">Nenhum prato pronto.</p>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span style={{
              color: activeTab === 3 ? '#d32f2f' : '#555',
            }}>
              Fechar Conta
            </span>
          }
          key="3"
        >
          {billRequests.length > 0 ? (
            billRequests.map(req => (
              <div key={req.id} className="mb-4">
                <div className="text-gray-600 mb-2">
                  <p><strong>Mesa:</strong> {req.table}</p>
                  <p><strong>Solicitado às:</strong> {req.time}</p>
                </div>
                <Button type="primary" className="bg-red-600 border-none hover:bg-red-500 w-full" onClick={() => handleBillRequest(req.id)}>
                  Fechar Conta
                </Button>
              </div>
            ))
          ) : (
            <p className="text-gray-500 text-center text-lg">Nenhum pedido de fechamento de conta.</p>
          )}
        </Tabs.TabPane>
      </Tabs>
    </ConfigProvider>
  );
};

export default WaiterTabs;