import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

type Product = {
  id: number;
  name: string;
  price: number;
  imgSrc: string;
  description?: string;
};

type ProductListProps = {
  products: Product[];
  onAdd: (id: number) => void;
  onRemove: (id: number) => void;
  quantities: Record<number, number>;
};

const ProductList: React.FC<ProductListProps> = ({ products, onAdd, onRemove, quantities }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const showDescriptionModal = (product: Product) => {
    setSelectedProduct(product);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedProduct(null);
  };

  return (
    <div className="p-4 space-y-4">
      {products.map(product => (
        <div key={product.id} className="flex items-center bg-white shadow-sm rounded-lg p-4">
          <img src={product.imgSrc} alt={product.name} className="h-16 w-16 rounded-lg mr-4" />
          <div className="flex-1">
            <h2 className="text-lg text-slate-500 font-semibold">{product.name}</h2>
            {product.description && (
              <Button
                type="primary"
                shape="round"
                icon={<InfoCircleOutlined />}
                size="small"
                onClick={() => showDescriptionModal(product)}
                style={{ marginTop: '0.5rem', backgroundColor: '#a3acb5'}}
              >
                Detalhes
              </Button>
            )}
          </div>
          <div className="flex flex-col items-center space-y-1">
            <span className="text-red-500 font-semibold mb-1">R$ {product.price.toFixed(2)}</span>
            <div className="flex items-center space-x-1">
            <button
              className={`bg-red-500 text-white px-3 py-1 rounded-md ${!quantities[product.id] ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-600'}`}
              onClick={() => onRemove(product.id)}
              disabled={!quantities[product.id]}
            >
              -
            </button>
            <span className="text-lg font-medium">{quantities[product.id] || 0}</span>
            <button
              className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600"
              onClick={() => onAdd(product.id)}
            >
              +
            </button>
            </div>
          </div>
        </div>
      ))}

{selectedProduct && (
  <Modal
    title={selectedProduct.name}
    visible={isModalVisible}
    onCancel={handleCloseModal}
    footer={[
      <Button key="close" type="default" onClick={handleCloseModal}>
        Fechar
      </Button>,
    ]}
  >
    <div className="flex items-center">
      <img
        src={selectedProduct.imgSrc}
        alt={selectedProduct.name}
        className="h-32 w-32 rounded-lg mr-4"
      />
      <p className="text-zinc-500">{selectedProduct.description}</p>
    </div>
  </Modal>
)}
    </div>
  );
};

export default ProductList;