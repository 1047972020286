import React, { useState } from 'react';
import { Modal, Button, List } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

type Product = {
  id: number;
  name: string;
  quantity?: number;
  price: number;
};

type OrderConfirmationModalProps = {
  isVisible: boolean;
  selectedItems: Product[];
  total: number;
  onOk: () => void;
  onCancel: () => void;
};

type Ingredient = {
  id: number;
  name: string;
};

const defaultIngredients: Record<number, Ingredient[]> = {
  1: [
    { id: 1, name: 'Cebola' },
    { id: 2, name: 'Picles' },
    { id: 3, name: 'Alface' },
  ],
  2: [
    { id: 1, name: 'Tomate' },
    { id: 2, name: 'Queijo' },
  ],
};

const OrderConfirmationModal: React.FC<OrderConfirmationModalProps> = ({
  isVisible,
  selectedItems,
  total,
  onOk,
  onCancel,
}) => {
  const [modifications, setModifications] = useState<Record<number, number[]>>({});

  const handleModify = (itemId: number) => {
    const currentModifications = modifications[itemId] || [];
    const itemIngredients = defaultIngredients[itemId] || [];

    const handleRemoveIngredient = (ingredientId: number) => {
      const newModifications = currentModifications.includes(ingredientId)
        ? currentModifications
        : [...currentModifications, ingredientId];
      setModifications(prev => ({
        ...prev,
        [itemId]: newModifications,
      }));
    };

    Modal.info({
      title: `Modificar ${selectedItems.find(item => item.id === itemId)?.name}`,
      content: (
        <List
          dataSource={itemIngredients}
          renderItem={ingredient => (
            <List.Item className="flex justify-between items-center">
              <span>{ingredient.name}</span>
              <Button
                type="link"
                danger
                icon={<DeleteOutlined />}
                onClick={() => handleRemoveIngredient(ingredient.id)}
                className="text-red-600"
              >
                Remover
              </Button>
            </List.Item>
          )}
        />
      ),
      onOk() {},
      okText: 'Fechar',
    });
  };

  return (
    <Modal title="Confirmar Pedido" visible={isVisible} onOk={onOk} onCancel={onCancel}>
      <h3 className="font-bold text-lg mb-2 text-gray-800">Itens Selecionados</h3>
      {selectedItems.map(item => (
        <div key={item.id} className="flex justify-between items-center mb-2 text-gray-700">
          <div className="flex items-center space-x-2">
            <span>{item.name} x {item.quantity ?? 0}</span>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => handleModify(item.id)}
              className="bg-red-500 hover:bg-red-600 text-white"
            >
              Modificar
            </Button>
          </div>
          <span>R$ {(item.price * (item.quantity ?? 1)).toFixed(2)}</span>
        </div>
      ))}
      <div className="mt-4">
        <span className="font-semibold">Total: R$ {total.toFixed(2)}</span>
      </div>
    </Modal>
  );
};

export default OrderConfirmationModal;